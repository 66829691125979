<template>
  <AdminPage contentComponent="AppAdminSpaceList" />
</template>

<script>
import AdminPage from '@/templates/admin/AppAdminPage'
import { getSpace } from '@/helper/firestore/space'
import { getEvent } from '@/helper/firestore/event'
import { putChunk } from '@/helper/firestore/chunk'

export default {
  name: 'TheSpaceList',
  components: {
    AdminPage,
  },
  data() {
    return {}
  },
  async mounted() {
    // console.log(this.$store.getters['place/chunks'].filter(c => !c.createAt))
    // await this.$_updateChunk()
  },
  computed: {},
  methods: {
    $_updateChunk: async function () {
      try {
        // とりあえずここでchunkのずれを適宜直しとく
        //   20230213 本番/開発のcreateAt, review, likesの修正完了
        //   function.js のリファクタを進めていくこと
        const chunks = this.$store.getters['place/chunks']
          .filter(d => (d.placeId !== 'chunkId' && d.placeId !== 'count'))
          .sort((a, b) => {
            if (a.placeId > b.placeId) {
              return 1;
            } else {
              return -1;
            }
          })
          // .filter(d => d.spaceName === '遊行寺（清浄光寺）')
          // .filter(d => d.spaceName === '忠生公園')
          
        console.log(chunks)

        for (let i = 0; i < chunks.length; i++) {
          console.log(`No. ${i}======================`)
          console.log(chunks[i]) 

          const place = chunks[i].type === 'space'
            ? await getSpace(chunks[i].placeId) 
            : await getEvent(chunks[i].placeId) 
          console.log(place, !place)

          if (!place) {
            console.log('++++++++++++++++++')
            console.log('no place data')
            console.log('++++++++++++++++++')
            continue
          }
          if (
            chunks[i].review === Number(place.review || 0) &&
            chunks[i].likes === Number(place.likes || 0)
          ) {
            console.log('*** continue ***')
            continue
          }

          const value = {
            ...chunks[i],
            createAt: place.createAt,
            review: Number(place.review || 0),
            likes: Number(place.likes || 0),
          }
          console.log(value)

          await putChunk(
            chunks[i].chunkId,
            chunks[i].placeId,
            value
          )
          await new Promise(resolve => setTimeout(resolve, 100))
        }
      } catch (error) {
        alert(error)
      }
    }
  },
}
</script>
